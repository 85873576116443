import { Component, Mixins } from "vue-property-decorator";
import Steps from "@/components/global/steps/Steps.vue";
import { AskForHelp } from "@/mixins/AskForHelp";
import { Getter } from "vuex-class";

export type dialogTypes = "success" | "error" | "info" | "alert";

export interface IDataDialog {
  open: boolean;
  type: dialogTypes;
  title?: string;
  subtitle?: string;
  message?: string | string[];
  action?: {
    name: string;
    execute: () => any;
    style?: Partial<CSSStyleDeclaration>;
  };
  hideHelpIcon?: boolean;
  toggleOption?: {
    code: string;
    message: string;
  };
  close?: {
    execute?: () => any;
    style?: Partial<CSSStyleDeclaration>;
  };
}

interface ISetDataDialog extends IDataDialog {
  isUserExists: boolean;
}

@Component({ name: "InfoDialog", components: { Steps } })
export default class InfoDialogController extends Mixins(AskForHelp) {
  toggleChecked = false;

  dataDialog: IDataDialog = {
    open: false,
    type: "success" as dialogTypes,
    title: "",
    subtitle: "",
    message: "",
    action: {
      execute: async () => null,
      name: "",
      style: undefined,
    },
    hideHelpIcon: false,
    close: {
      execute: async () => {},
      style: undefined,
    },
  };

  get dialogType() {
    const types = {
      success: "primary lighten-1 success-section",
      alert: "tertiary error-section",
      error: "red error-section",
      info: "primary lighten-2 info-section",
    };
    return types[this.dataDialog.type] || types.success;
  }

  async created(): Promise<void> {
    this.$bus.$on("openDialog", this.setDataDialog);
    this.$bus.$on("closeDialog", () => {
      this.dataDialog.open = false;
    });
  }

  async setDataDialog(data: ISetDataDialog) {
    this.dataDialog.open = false;

    if (data.toggleOption && localStorage.getItem(data.toggleOption.code) === "true") return;

    this.dataDialog = {
      ...data,
      open: true,
    };
  }

  async executeAction() {
    if (this.toggleChecked) localStorage.setItem(this.dataDialog.toggleOption?.code!, "true");
    if (this.doesTheDialogHaveAction) {
      await this.dataDialog.action?.execute();
      this.handleClose();
    }
  }

  handleClose() {
    this.dataDialog?.close?.execute && this.dataDialog?.close?.execute();
    if (this.toggleChecked) localStorage.setItem(this.dataDialog.toggleOption?.code!, "true");
    this.dataDialog.open = false;
  }

  get shouldShowHelpButton() {
    if (this.dataDialog.hideHelpIcon) return false;
    return ["error", "info", "alert"].includes(this.dataDialog.type) && this.showHelpButtons;
  }

  get shouldShowCloseButton() {
    if (this.doesTheDialogHaveAction) {
      return !["error", "alert"].includes(this.dataDialog.type);
    }
    return true;
  }

  get doesTheDialogHaveAction() {
    return !!this.dataDialog.action;
  }

  get shouldCloseButtonBeOutlined() {
    return this.doesTheDialogHaveAction;
  }
}
