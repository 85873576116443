import OccupationFactory from "@/models/factories/OccupationFactory";
import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";

export default class OccupationsUserResource extends AbstractOnboardResource {
	/**
	 *
	 * @param covenantId
	 * @param roleId
	 * @param financial_information
	 * @param permanence_time
	 * @param admission_date
	 * @param params
	 * @param {boolean} shouldQueue
	 * @return {Promise<ResourceReturnModel>}
	 */
	async create({
		covenantId,
		roleId,
		financial_information,
		permanence_time,
		admission_date,
		shouldQueue = true,
		...params
	}) {
		try {
			const response = await this.api.post({
				route: "/occupations",
				payload: {
					covenant_id: covenantId,
					role_id: roleId,
					financial_information,
					permanence_time,
					admission_date,
				},
				...params,
				shouldQueue,
			});

			return this.resourceReturnFactory.create({
				object: {
					error: false,
					data: OccupationFactory.create({
						object: response?.data?.response?.[0] || {},
					}),
					responseObject: response,
				},
			});
		} catch (e) {
			return this.resourceReturnFactory.create({
				object: {
					error: true,
					data: OccupationFactory.create(),
					responseObject: e,
				},
			});
		}
	}

	/**
	 *
	 * @param covenantId
	 * @param roleId
	 * @return {Promise<ResourceReturnModel>}
	 */
	async find({ covenantId, roleId }) {
		try {
			const response = await this.api.get({
				route: "/occupations",
				params: { covenant_id: covenantId, role_id: roleId },
			});

			return this.resourceReturnFactory.create({
				object: {
					error: false,
					responseObject: response,
					data: OccupationFactory.create({
						object: response?.data?.response?.[0] || {},
					}),
				},
			});
		} catch (e) {
			return this.resourceReturnFactory.create({
				object: {
					error: true,
					responseObject: e,
					data: OccupationFactory.create(),
				},
			});
		}
	}

	/**
	 *
	 * @param covenantId
	 * @param roleId
	 * @param shouldQueue
	 * @return {Promise<*|undefined>}
	 */
	async findOrCreate({ covenantId, roleId, shouldQueue }) {
		const { data, responseObject, error } = await this.find({
			covenantId,
			roleId,
			shouldQueue,
		});
		if (error && responseObject.status === 404) {
			return await this.create({ roleId, covenantId });
		}
		return data;
	}

	/**
	 * @param {{}} formData
	 * @param {number=} formData.covenantId
	 * @param {number=}formData.roleId
	 * @param {number=} formData.userId
	 * @param {boolean=}  formData.shouldQueue
	 * @return {Promise<ResourceReturnModel>}
	 */
	async adminFind({ covenantId, roleId, userId, shouldQueue }) {
		try {
			const response = await this.api.get({
				route: `/users/${userId}/occupations`,
				params: { covenant_id: covenantId, role_id: roleId },
				shouldQueue,
			});
			return this.resourceReturnFactory.create({
				object: {
					error: false,
					responseObject: response,
					data: OccupationFactory.create({
						object: response?.data?.response[0] || {},
					}),
				},
			});
		} catch (e) {
			return this.resourceReturnFactory.create({
				object: {
					error: true,
					responseObject: e,
					data: OccupationFactory.create(),
				},
			});
		}
	}

	/**
	 *
	 * @param covenantId
	 * @param roleId
	 * @param userId
	 * @param params
	 * @return {Promise<ResourceReturnModel>}
	 */
	async adminUpdate({ covenantId, roleId, userId, ...params }) {
		try {
			const response = await this.api.put({
				route: `/users/${userId}/occupations`,
				payload: { covenant_id: covenantId, role_id: roleId, ...params },
			});
			return this.resourceReturnFactory.create({
				object: {
					error: false,
					responseObject: response,
					data: OccupationFactory.create({
						object: response?.data?.response || {},
					}),
				},
			});
		} catch (e) {
			return this.resourceReturnFactory.create({
				object: {
					error: true,
					responseObject: e,
					data: OccupationFactory.create(),
				},
			});
		}
	}

	/**
	 *
	 * @param covenantId
	 * @param roleId
	 * @param userId
	 * @param params
	 * @return {Promise<ResourceReturnModel>}
	 */
	async adminCreate({ covenantId, roleId, userId, ...params }) {
		try {
			const response = await this.api.post({
				route: `/users/${userId}/occupations`,
				payload: { covenant_id: covenantId, role_id: roleId, ...params },
			});
			return this.resourceReturnFactory.create({
				object: {
					error: false,
					responseObject: response,
					data: OccupationFactory.create({
						object: response?.data?.response || {},
					}),
				},
			});
		} catch (e) {
			return this.resourceReturnFactory.create({
				object: {
					error: true,
					responseObject: e,
					data: OccupationFactory.create(),
				},
			});
		}
	}

	/**
	 *
	 * @param covenantId
	 * @param roleId
	 * @param userId
	 * @param shouldQueue
	 * @return {Promise<{error: boolean}|(*&{error: boolean})|undefined|ResourceReturnModel>}
	 */
	async adminFindOrCreate({ covenantId, roleId, userId, shouldQueue }) {
		const foundOccupation = await this.adminFind({
			covenantId,
			roleId,
			userId,
			shouldQueue,
		});
		if (
			foundOccupation.error &&
			foundOccupation.responseObject.status === 404
		) {
			return await this.adminCreate({ roleId, covenantId, userId });
		}
		return foundOccupation;
	}
}
